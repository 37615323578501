/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

export default {
	namespaced: true,
	
	state: {
		key: ''
	},
	
	mutations: {
		
		//set or clear user
		setKey(state, value) {
			if (value) {
				state.key = value;
				localStorage.setItem('x-key', state.key);
			}
		},
		
		clearKey(state) {
			state.key = null;
			localStorage.removeItem('x-key');
		}
	},
	
	actions: {
		setKey({commit}, value) {
			commit('setKey', value);
		},
		
		clear({commit}) {
			commit('clearKey', null);
		}
	},
	
	getters: {
		getKey: state => state.key
	}
}
