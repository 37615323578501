import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

/**
 * import vueMeta
 */
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
	keyName: 'metaInfo',
	tagIDKeyName: 'vmid',
	refreshOnceOnNavigation: true
});

/**
 * import LoadScript support
 */
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);

/**
 * import cookies
 */
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

/**
 * Import screen
 */
import VueScreen from 'vue-screen';

Vue.use(VueScreen);

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

Vue.use(CoolLightBox);

/**
 * import lodash
 */
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, {name: '_lodash', lodash: lodash});

/**
 * import axios
 */
import axios from 'axios'
import globalMixins from "@/mixins/globalMixins";
import debounce from "lodash/debounce";

let M360BaseUrl = process.env.VUE_APP_M360_GATEWAY;
const base = axios.create({
	baseURL: M360BaseUrl,
	headers: {'Content-Type': 'application/json'}
});
Vue.prototype.$http = base;

const DynamicAbstract = Vue.component('dynamic-abstract', {
	props: {
		context: {
			type: Object
		}
	},
	mixins: [globalMixins],
	created() {
		const _self = this;
		//check attach methods
		this.context.methods = {
			doLogout: this.doLogout
		};
		
		if (this.context.properties.methods) {
			for (let i in this.context.properties.methods) {
				this.context.methods[i] = new Function(this.context.properties.methods[i].arguments, this.context.properties.methods[i].logic);
			}
		}
		
		//check attach computed values
		this.context.computed = {};
		if (this.context.properties.computed) {
			for (let i in this.context.properties.computed) {
				this.context.computed[i] = new Function(this.context.properties.computed[i]);
			}
		}
		
		//check and fix total pages calculation
		this.setItemsAndFixMeta(this.context.properties.data);
		
		//attach pagination method
		this.context.methods['paginate'] = function (pageNumber) {
			
			//update the payload to send to items api
			let newMeta = this._.cloneDeep(_self.context.properties.data.meta);
			newMeta.page = pageNumber;
			delete newMeta.pageTotal;
			
			//get new items and meta info from api
			_self.getSendData({
				url: '/abcbridgeapi/',
				method: 'post',
				body: {
					"list": true,
					"meta": newMeta
				}
			}).then(response => {
				
				//update meta and items
				_self.setItemsAndFixMeta(response);
				_self.context.properties.data.items = response.items;
				_self.context.properties.data.meta = response.meta;
			})
		};
		
		//attach gotopage method
		this.context.methods['goToPage'] = this.goToPage;
	},
	updated: debounce(function () {
		this.$nextTick(async () => {
			await this.hookAllLinks();
		});
	}, 1000),
	mounted: debounce(function () {
		this.$nextTick(async () => {
			await this.hookAllLinks();
		});
	}, 1000),
	methods: {
		setItemsAndFixMeta(data) {
			if (data && data.meta) {
				if (Object.hasOwnProperty.call(data.meta, 'page')) {
					data.meta.parent = this.getURLLocalGlobal(data.meta.parent).replace(/[\\/]{2,}/, "/");
					data.meta.pageTotal = Math.floor(data.meta.total / data.meta.pageSize);
					if (data.meta.page === 0) {
						data.meta.page = 1;
					}
				}
			}
		}
	},
	render(e) {
		const _self = this;
		const render = {
			methods: _self.context.methods,
			computed: _self.context.computed,
			mixins: [globalMixins],
			data() {
				return {
					data: _self.context.properties.data
				}
			},
			template: _self.context.properties.template || '<div></div>'
		}
		return e(render);
	}
});

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
	beforeCreate() {
		store.dispatch('init');
	},
	components: {
		DynamicAbstract
	}
}).$mount('#app')

Vue.directive('init', {
	bind: function (el, binding, vnode) {
		if (vnode.context[binding.expression] && typeof vnode.context[binding.expression] === 'function') {
			vnode.context[binding.expression]();
		}
	}
});