<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
};
</script>

<style>
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"),
  url(./assets/fonts/Nimbus-Sans-D-OT-Light_32752.ttf) format("truetype");
}
</style>

