const routes = [
	{
		path: '/error404',
		name: 'error404',
		index: 2,
		component: () => import('@/views/template/Error')
	},
	{
		path: '',
		component: () => import('@/views/template/Index'),
		children: [
			{
				path: '/',
				name: 'HomePage',
				component: () => import('@/views/template/DynamicPage')
			},
			{
				path: '/login',
				name: 'Login',
				props: true,
				component: () => import('@/views/pages/User/Login')
			},
			{
				path: '/member-access',
				name: 'MemberAccess',
				props: true,
				component: () => import('@/views/pages/User/MemberAccess')
			},
			{
				path: '/forgot-password',
				name: 'ForgotPassword',
				props: true,
				component: () => import('@/views/pages/User/ForgotPassword')
			},
			{
				path: '/user',
				name: 'User',
				props: true,
				component: () => import('@/views/pages/User/Profile')
			},
			{
				path: '/search/:apikey?',
				name: 'Search',
				props: true,
				component: () => import('@/views/pages/Search')
			},
			{
				path: '/resources/botanical-adulterants-prevention-program/',
				name: 'Botanical Adulterants Prevention Program',
				props: true,
				component: () => import('@/views/pages/bapp/index')
			},
			{
				path: '/resources/herbmedpro/herb-list',
				name: 'Herb Record',
				props: true,
				component: () => import('@/views/pages/hmp/listHerb')
			},
			
			{
				path: '/resources/herbmedpro/herb-list/:herbName',
				name: 'Herb Record',
				props: true,
				component: () => import('@/views/pages/hmp/viewHerb')
			},
			{
				path: '/resources/herbmedpro/component-list/:herbName',
				name: 'Herb Record',
				props: true,
				component: () => import('@/views/pages/hmp/herbComponent')
			},
			{
				path: '/resources/herbmedpro/advanced-search',
				name: 'Advanced Search',
				props: true,
				component: () => import('@/views/pages/hmp/advSearch')
			},
			{
				path: '/resources/herbalgram/departments/:page/',
				name: 'HerbalGram Department',
				props: true,
				component: () => import('@/views/pages/herbalGram/departmentView')
			},
			{
				path: '/resources/herbalgram/free-samples/',
				name: 'HerbalGram Free Samples',
				props: true,
				component: () => import('@/views/pages/herbalGram/freeSamples')
			},
			{
				path: '/resources/herbalgram/current-issue/',
				name: 'HerbalGram Current Issue',
				props: true,
				component: () => import('@/views/pages/herbalGram/current')
			},
			{
				path: '/resources/herbalgram/issues/:issueNb/table-of-contents/:pageName/',
				name: 'HerbalGram Article',
				props: true,
				component: () => import('@/views/pages/herbalGram/page')
			},
			{
				path: '/resources/herbalegram/',
				name: 'Herbal E Gram',
				props: true,
				component: () => import('@/views/pages/heg/home')
			},
			{
				path: '/resources/herbclip/free-samples/',
				name: 'Herb Clip Free Sample',
				props: true,
				component: () => import('@/views/pages/hc/freeSamples')
			},
			{
				path: '/resources/herbclip',
				name: 'Herb Clip',
				props: true,
				component: () => import('@/views/pages/hc/home')
			},
			{
				path: '/news/herbal-mediawatch/:year?/',
				name: 'News MediaWatch',
				props: true,
				component: () => import('@/views/pages/news/mediawatch')
			},
			{
				path: '/resources/commission-e-monographs/',
				name: 'Complete German Commission E Monographs Home',
				props: true,
				component: () => import('@/views/pages/gcem/home')
			},
			{
				path: '/resources/commission-e-monographs/*',
				name: 'Complete German Commission E Monographs',
				props: true,
				component: () => import('@/views/pages/gcem/page')
			},
			{
				path: '/resources/abc-clinical-guide/',
				name: 'The ABC Clinical Guide to Herbs',
				props: true,
				component: () => import('@/views/pages/clinicalGuide/home')
			},
			{
				path: '/resources/abc-clinical-guide/*',
				name: 'The ABC Clinical Guide to Herbs items',
				props: true,
				component: () => import('@/views/pages/clinicalGuide/page')
			},
			{
				path: '/adopt-an-herb',
				name: 'Adopt an Herb',
				props: true,
				component: () => import('@/views/template/DynamicPage'),
			},
			{
				path: '/adopt-an-herb/*',
				name: 'Adopt an Herb items',
				props: true,
				component: () => import('@/views/pages/adopt/page')
			},
			{
				path: '/:page/:subPath?/:subPath2?/:subPath3?/:subPath4?/:subPath5?/:subPath6?/:subPath7?/:subPath8?/:subPath9?/:subPath10?/:subPath11?/:subPath12?/:subPath13?/:subPath14?/',
				name: 'DynamicPage',
				props: true,
				component: () => import('@/views/template/DynamicPage')
			}
		]
	},
	{
		path: '*',
		component: () => import('@/views/template/Index'),
		children: [
			{
				name: '404 Error',
				path: '',
				component: () => import('@/views/template/Error'),
			},
		],
	},
];

module.exports = routes;
