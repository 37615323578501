/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

export default {
	namespaced: true,
	
	state: {
		breadcrumbs: ''
	},
	
	mutations: {
		
		//set or clear user
		set( state, value ) {
			state.breadcrumbs = value;
		}
	},
	
	actions: {
		set( { commit }, value ) {
			commit( 'set', value );
		},
	},
	
	getters: {
		get:(state) => () => {
			return state.breadcrumbs;
		}
	}
}
